import React, {Component} from 'react'
import {browserHistory} from 'react-router'
import ContainerHeader from '../util/ContainerHeader.jsx'
import {connect} from 'react-redux'
import {
  FILTER_TYPE_TASK,
  SIDEBAR_WIDTH_EXPANDED,
  SIDEBAR_WIDTH_UNEXPANDED,
  TASK_PLACE_HOLDER,
  TASK_TYPE
} from '../../Constants'
import TaskDetail from './TaskDetail.jsx'
import {setPlaceHolder} from '../util/Utils'
import GenericModalConfirm from '../util/GenericModalConfirm.jsx'
import {loadLocations, loadMakeAndModel, searchInventory} from '../../actions'
import isEmpty from 'lodash/isEmpty.js'
import ModalConfirmDelete from '../util/ModalConfirmDelete'
import {deleteTaskApi, loadBoards, updateTaskActiveApi} from '../../Api'
import ModalConfirmDeactivate from '../util/ModalConfirmDeactivate'
import {forEach, find} from 'lodash'
import {SUCCESSFUL_CODE} from '../../../common/Constants'

class TaskEdit extends Component {
  constructor() {
    super();
    this.back   = this.back.bind(this);
    this.save   = this.save.bind(this);
    this.handleModalChange  = this.handleModalChange.bind(this);
    this.handleUpdateData   = this.handleUpdateData.bind(this);
    this.validationModal    = this.validationModal.bind(this);
    this.updateTitleTask    = this.updateTitleTask.bind(this);
    this.updateEditTask     = this.updateEditTask.bind(this);
    this.openModalDelete    = this.openModalDelete.bind(this);
    this.openModalActive    = this.openModalActive.bind(this);
    this.changeActiveTask   = this.changeActiveTask.bind(this);
    this.deleteTask         = this.deleteTask.bind(this);
    this.state = {
      showModal:false,
      message:"Are you sure you want to save these changes?",
      hasChange:false,
      fromBack:false,
      titleTask:"",
      editTask: true,
      showModalDeleteTask : false,
      showModalActiveTask : false,
      repairId:null,
      isLoading: true
    }

  }

  async componentDidMount(){
    const {searchInventory,loadMakeAndModel,loadLocations, taskFilter} = this.props;
    const {id, carId, repairId} = this.props.params;

    this.setState({isLoading: true})
    let state = {isLoading: false}

      if(taskFilter.statusesByType === undefined){
        const {responseCode, data} = await loadBoards()
        if (responseCode === SUCCESSFUL_CODE) {
          const statusesByType = data.statusesByType
          forEach(statusesByType, st => {
            st.statuses = JSON.parse(st.statuses)
          })
          state.statusesByType = statusesByType
        } else {
          this.setState(state)
        }
      }else{
        state.statusesByType = taskFilter.statusesByType
      }
      if(id === 'new' && carId && repairId){
        state.hasChange = true
      }
      await searchInventory({active: true})
      await loadMakeAndModel();
      await loadLocations();
      this.setState(state)
  }

  componentDidUpdate(){
    setPlaceHolder("menu-search",TASK_PLACE_HOLDER);
  }

  updateTitleTask(title){
    this.setState({titleTask:title})
  }

  updateEditTask(status){
    const isDisabled = status === FILTER_TYPE_TASK[1].value || status === FILTER_TYPE_TASK[2].value ;
    this.setState({editTask: !isDisabled})
  }

  openModalDelete(evt){
    let {id} = this.props.params;
    evt.stopPropagation();
    this.setState({showModalDeleteTask: true});
  }

  openModalActive(evt){
    evt.stopPropagation();
    this.setState({showModalActiveTask: true});
  }

 changeActiveTask() {
    let {id} = this.props.params;
    updateTaskActiveApi(id).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({showModalDeleteTask:false})
        browserHistory.push({pathname: "/main/task"});
      }
    })
  }
 deleteTask() {
    let {id} = this.props.params;
    deleteTaskApi(id).then(({responseCode}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({showModalDeleteTask:false})
        browserHistory.push({pathname: "/main/task"});
      }
    })
  }
  back(){
    const {hasChange} = this.state;
    const {carId} = this.props.params
    const {cars} = this.props
    const {titleTask} = this.state

    if (hasChange){
      if(titleTask === ""){
        browserHistory.push({pathname: "/main/task"});
      }else{
        this.validationModal();
        this.setState({fromBack:true})
      }
    } else if (!isEmpty(carId)) {
      const carFound = find(cars, c => c.id === carId)
      browserHistory.push({pathname: `/main/inventory/${carFound?.vin}`})
    } else {
      browserHistory.push({pathname: "/main/task"});
    }
  }

  save(){
    let {carId, repairId} = this.props.params
    if(!repairId){
      repairId = this.state.repairId
    }
    this.setState({showModal:false},() => {
      this.taskDetail.validationData(carId, repairId);
    });
  }

  updateRepairId = (repairId) => {
    this.setState({repairId})
  }

  handleModalChange(){
    const {fromBack} = this.state;
    this.setState({showModal:false});
    if(fromBack){
      this.setState({hasChange:false});
      browserHistory.push({pathname: "/main/task"});
    }
  }

  handleUpdateData(id){
    this.setState({[id]:true})
  }

  validationModal(){
    let error = this.taskDetail.getError();
    if(isEmpty(error))
      this.setState({showModal:true});
    else
      this.taskDetail.setError();
  }

  render() {
    let {id, carId, repairId} = this.props.params;
    let {showSideBar, cars, makes, models, locations, activeUsers, email, taskFilter} = this.props;
    const { repairSelected } = this.props.location.state || {}
    const {showModal,showModalDeleteTask, showModalActiveTask, hasChange, message, titleTask, editTask, isLoading, statusesByType} = this.state;
    let left;
    if(showSideBar){
      left = SIDEBAR_WIDTH_EXPANDED;
    } else{
      left = SIDEBAR_WIDTH_UNEXPANDED;
    }

    const type = !isEmpty(carId) ? TASK_TYPE.REPAIR : taskFilter?.type;
    const isNewTask  = (id === 'new') ;
    return (
      <div className="container-task-edit">
        <ContainerHeader  style={{marginLeft: "0" , width : "55px"}} ref={el => this.containerHeader = el} title="Task"
                          isEdit={true} data={titleTask} actionBack={this.back} save={this.validationModal}
                          hasChange={hasChange} left={left} showOnMobile={true} isEditTask = {editTask} isNewTask={isNewTask}
                          deleteTask = {this.openModalDelete} activeTask={this.openModalActive} archivedTask = {this.changeActiveTask} pageSelected="task"/>
        {
          !isLoading &&
          <TaskDetail id={id}
                      carId={carId}
                      ref={el => this.taskDetail = el}
                      handleUpdateData={this.handleUpdateData}
                      back={this.back}
                      isEdit={true}
                      hasChange={hasChange}
                      save={this.save}
                      users={activeUsers}
                      makes={makes}
                      models={models}
                      cars={cars}
                      updateTitleTask={this.updateTitleTask}
                      updateEditTask={this.updateEditTask}
                      locations={locations}
                      type={type}
                      email={email}
                      repairCost={ repairSelected ? {...repairSelected, id: repairId} : repairSelected}
                      updateRepairId={this.updateRepairId}
                      statusesByType={statusesByType}
          />
        }
        {
          showModal &&
            <GenericModalConfirm show={showModal}
                                 message={message}
                                 save={this.save}
                                 onHide={this.handleModalChange}
                                 showButtonCancel={true}
                                 buttonLabelSave="Save"
                                 classButtonSave="btn-save"
            />
        }

        {
          showModalDeleteTask &&
          <ModalConfirmDelete show={showModalDeleteTask}
                              showButtonDelete={true}
                              onSubmit={this.deleteTask}
                              onHide={() => {
                                this.setState({showModalDeleteTask: false})
                              }}
                              classButtonDelete="btn-delete-task"
                              buttonLabelDelete="Yes, Delete"
                              message={"Do you want to delete this task permanently?"}
                              body={<div className="container-message-delete-task-in">
                                <span className="title">Do you want to delete this task permanently?</span>
                                <span className="sub-title">This action is irreversible</span>
                              </div>}
                              styleModal="modal-delete"
                              styleRight="panel-right-delete-user"/>
        }

        {
          showModalActiveTask &&
          <ModalConfirmDeactivate
                                show={showModalActiveTask}
                                onSubmit={this.changeActiveTask}
                                onHide={() => {this.setState({showModalActiveTask:false})}}
                                showButton={true}
                                isBodyCustom={true}
                                bodyMessage={<div className="container-message-activate-task-in">
                                  <span className="title">Do you want to activate this task?</span>
                                  <span className="sub-title">Once activated, it'll appear on principal task page</span>
                                </div>}
                                classButtonDelete="btn-delete"
                                buttonLabelDelete={"Yes, activate."}
                                viewMobile={false}
                                iconQuestion={"icon/ic_question_warning_activate.svg" }
                                showAnotherMessage = {true}/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    showSideBar:state.showSideBar,
    cars:state.cars,
    makes:state.makes,
    models:state.models,
    locations:state.locations,
    email:state.email,
    taskFilter:state.taskFilter,
    activeUsers:state.activeUsers,
  }
};

export default connect(mapStateToProps,{searchInventory,loadMakeAndModel,loadLocations})(TaskEdit)