import React from 'react'
import { Route, IndexRoute } from 'react-router'
import Inventory from './newComponents/inventory/Inventory.jsx'
import ContainerInventory from './newComponents/inventory/ContainerInventory.jsx'
import InventoryEdit from './newComponents/inventory/InventoryEdit.jsx'
import Customer from './newComponents/customer/CustomerContainer.jsx'
import App from './newComponents/App.jsx'
import Dashboard from './newComponents/dashboard/Dashboard.jsx'
import DashboardProfile from './newComponents/dashboard/DashboardProfile.jsx'
import Message from './newComponents/message/Message.jsx'
import TaskContainer from './newComponents/task/TaskContainer.jsx'
import MessageContainer from './newComponents/message/MessageContainer.jsx'
import Setting from './newComponents/setting/Setting.jsx'
import SettingUser from './newComponents/setting/userSetting/SettingUser.jsx'
import Forms from './newComponents/setting/formSetting/Forms.jsx'
import AttributesNew from './newComponents/setting/attribute/Attributes.jsx'
import Templates from './newComponents/setting/template/Templates.jsx'
import TemplateNew from './newComponents/setting/template/Template.jsx'
import FormSetting from './newComponents/setting/formSetting/FormSetting.jsx'
import FinanceContainer from './newComponents/finance/FinanceContainer.jsx'
import Finance from './newComponents/finance/Finance.jsx'
import MessageSent from './newComponents/message/MessageSent.jsx'
import PageOnBuild from './newComponents/setting/PageOnBuild.jsx'
import SettingMarketing from './newComponents/setting/SettingMarketing.jsx'
import Marketing from './newComponents/marketing/Marketing.jsx'
import Testimonials from './newComponents/marketing/Testimonials.jsx'
import TaskEdit from './newComponents/task/TaskEdit.jsx'
import FinanceEdit from './newComponents/finance/FinanceEdit.jsx'
import CustomerInfo from './newComponents/customer/CustomerInfo.jsx'
import CustomerNew from './newComponents/customer/CustomerNew.jsx'
import CustomerSale from './newComponents/customer/CustomerSale.jsx'
import Locations from './newComponents/setting/locationSetting/Locations.jsx'
import InventorySetting from './newComponents/setting/inventorySetting/InventorySetting.jsx'
import LoginAccount from './newComponents/register/LoginAccount.jsx'
import ContainerSale from './newComponents/sale/ContainerSale.jsx'
import SaleEdit from './newComponents/sale/SaleEdit.jsx'
import RolList from './newComponents/setting/rol/RolList.jsx'
import FinanceView from './newComponents/sale/FinanceView.jsx'
import SaleSetting from './newComponents/setting/SaleSetting/SaleSetting'
import BalanceDetail from './newComponents/sale/BalanceDetail'
import BillingContainer from './newComponents/setting/billing/BillingContainer'
import SuperUserRoutes from './routes/SuperUsers'
import MarketingPageRoutes from './routes/MarketingPageRoutes'
import SettingSEOInfo from './newComponents/setting/marketingSetting/SettingSEOInfo.jsx'
// import ChatBox from './newComponents/message/ChatMessage/ChatBox.jsx'
import ReviewsRoutes from './routes/ReviewsRoutes'
import DashboardSubscriptions from './newComponents/dashboard/DashboardSubscriptions'
import DashboardUpdatePlan from './newComponents/dashboard/DashboardUpdatePlan'


import StyleGuide from './newComponents/styleGuide/StyleGuide'


const Routes = (
  <Route>
    {MarketingPageRoutes}
    {/*Where should we put this Routes*/}
    <Route path="/old_login" component={LoginAccount}/>
    <Route path="/dev/styleGuide" component={StyleGuide}/>

    <Route path="/main" component={App}>
      {ReviewsRoutes}
      <Route path="profile" component={DashboardProfile}/>
      {/*<Route path="subscriptions" component={DashboardSubscriptions}/>*/}
      <Route path="updatePlan" component={DashboardUpdatePlan}/>
      <Route path="message" component={MessageContainer}>
        <Route path="finance" component={FinanceContainer}>
          <IndexRoute component={Finance}/>
          <Route path=":id" component={FinanceEdit}/>
        </Route>
        {/*<Route path="sent" component={MessageSent}/>*/}
        {/*<Route path="chat-box" component={ChatBox}/>*/}
        <IndexRoute component={Message}/>
      </Route>
      <Route path="task/repair/vin/:vin" component={TaskContainer}/>
      <Route path="task" component={TaskContainer}>
        <Route path=":id/repair/:repairId/car/:carId" component={TaskEdit}/>
        <Route path=":id" component={TaskEdit}/>
      </Route>
      <Route path="dashboard" component={Dashboard}/>
      <Route path="inventory/search" component={Inventory}/>
      <Route path="inventory" component={ContainerInventory}>
        <Route path=":vin" component={InventoryEdit}/>
      </Route>
      <Route path="customer" component={Customer}>
        <Route path="edit/:id" component={CustomerInfo}/>
        <Route path="new" component={CustomerNew}/>
        <Route path="edit/:customerId/sales/:saleId/:tab" component={CustomerSale}/>
      </Route>
      <Route path="marketing" component={Marketing}>
        {/*<Route path="statistics" component={PageOnBuild}/>*/}
        <Route path="testimonials" component={Testimonials}/>
        {/*<Route path="ads" component={PageOnBuild}/>
        <Route path="campaigns" component={PageOnBuild}/>*/}
        <IndexRoute component={Testimonials}/>
      </Route>

      <Route path="customer/search" component={Customer}/>
      <Route path="forms" component={Forms}/>
      <Route path="sale" component={ContainerSale}>
        <Route path="new" component={ContainerSale}/>
        <Route path="vin/:vin" component={ContainerSale}/>
        <Route path=":saleId" component={SaleEdit}>
          <Route path="finance/:id" component={FinanceView}/>
          <Route path="balance" component={BalanceDetail}/>
        </Route>
      </Route>
      <Route path="setting" component={Setting}>
        {/*<Route path="general" component={PageOnBuild}/>*/}
        <Route path="billingPayment" component={BillingContainer}/>
        <Route path="inventory" component={InventorySetting}/>
        <Route path="location" component={Locations}/>
        {/*<Route path="customer" component={PageOnBuild}/>*/}
        <Route path="user" component={SettingUser}/>
        <Route path="rol" component={RolList}/>
        <Route path="permission" component={PageOnBuild}/>
        <Route path="task" component={PageOnBuild}/>
        <Route path="form" component={Forms}>
          <Route path=":id" component={FormSetting}/>
        </Route>
        {/*<Route path="contract" component={PageOnBuild}/>*/}
        <Route path="attribute" component={AttributesNew}/>
        <Route path="template" component={Templates}/>
        <Route path="template/:id" component={TemplateNew}/>
        <Route path="marketing" component={SettingMarketing}/>
        <Route path="seo" component={SettingSEOInfo}/>
        <Route path="sales" component={SaleSetting}/>
        <IndexRoute component={InventorySetting}/>
      </Route>

      {/*SuperUsers*/}
      {SuperUserRoutes}

      <IndexRoute component={Dashboard}/>
    </Route>
  </Route>
)

export default Routes
