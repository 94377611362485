import React from 'react'
import PropTypes from 'prop-types'
import {DragDropContext} from 'react-beautiful-dnd'
import {findIndex} from 'lodash'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (sections, droppableSource, droppableDestination) => {
  const sectionsToUpdate = [...sections];
  const sourceIndex = findIndex(sectionsToUpdate, function (section) {return section.id === droppableSource.droppableId});
  const destinationIndex = findIndex(sectionsToUpdate, function (section) {return section.id === droppableDestination.droppableId});

  const sourceClone = [...sectionsToUpdate[sourceIndex].items];
  const destClone = [...sectionsToUpdate[destinationIndex].items];
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  removed.status = droppableDestination.droppableId;
  destClone.splice(droppableDestination.index, 0, removed);
  sectionsToUpdate[sourceIndex].items = sourceClone;
  sectionsToUpdate[destinationIndex].items = destClone;

  return sectionsToUpdate;
};

const getItemsToUpdate = (sections, droppableSource, droppableDestination) => {
  const sourceIndex = findIndex(sections, function (section) {return section.id === droppableSource.droppableId});
  const destinationIndex = findIndex(sections, function (section) {return section.id === droppableDestination.droppableId});
  const items = [];
  sections[sourceIndex].items.forEach((item,index) => {
    items.push({id:item.id, order: (index + 1)})
  });
  sections[destinationIndex].items.forEach((item,index) => {
    const itemToInsert = {id:item.id, order: (index + 1)};
    if(index === droppableDestination.index)
      itemToInsert.status = item.status;

    items.push(itemToInsert);
  });
  return items
};

const getSectionIndex = (sections, id) => {
  const index = findIndex(sections, function (section) {
    return section.id === id
  });
  return index;
};

const DragDrop = (props) => {
  const {sections,children} = props;

  const onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    let itemsToUpdate = [];
    if (source.droppableId === destination.droppableId) {
      const sectionsToReorder = [...sections];
      const sectionIndex = getSectionIndex(sectionsToReorder,source.droppableId);
      const items = reorder(
        sectionsToReorder[sectionIndex].items,
        source.index,
        destination.index
      );

      sectionsToReorder[sectionIndex].items = items;
      items.forEach((item,index) => {
        itemsToUpdate.push({id: item.id, order: (index + 1)});
      });
      props.reorder(sectionsToReorder,itemsToUpdate);
    } else {
      const result = move(
        sections,
        source,
        destination
      );
      itemsToUpdate = getItemsToUpdate(result,source,destination);
      props.move(result,itemsToUpdate);
    }
  };

  return (
    <DragDropContext onDragEnd={props.onDragEnd ? props.onDragEnd : onDragEnd}>
      {children}
    </DragDropContext>
  );
};

DragDrop.propTypes = {
  sections: PropTypes.array.isRequired,
  reorder: PropTypes.func.isRequired,
  move: PropTypes.func.isRequired
};

export default DragDrop;