import {Droppable} from 'react-beautiful-dnd'
import React from 'react'
import PropTypes from 'prop-types'

const getListStyle = (isDraggingOver,droppableStyle, draggingOverStyle) => {
  const styleWrapper = isDraggingOver ? draggingOverStyle : {};
  return {...droppableStyle,...styleWrapper}
};

const DPDroppable = (props) => {
  const {droppableId,droppableStyle,draggingOverStyle,children, className} = props;
  return(
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={getListStyle(snapshot.isDraggingOver,droppableStyle,draggingOverStyle)}
          className={className || null}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
};

DPDroppable.defaultProps = {
  droppableStyle: {
    backgroundColor: '#f5f5f5'
  },
  draggingOverStyle: {
    backgroundColor: 'lightblue',
    zIndex: 1,
    borderRadius: 5
  }
};


DPDroppable.propTypes = {
  droppableId: PropTypes.string.isRequired ,
  droppableStyle: PropTypes.object,
  draggingOverStyle: PropTypes.object,
  className: PropTypes.string,
};

export default DPDroppable;