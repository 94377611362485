/**
 * Created by javierl on 3/23/16
 */

import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux'
import {
  SEARCH_INVENTORY_SUCCESS,
  LOAD_MESSAGES_SUCCESS,
  LOAD_FINANCES_SUCCESS,
  LOAD_MAKES_SUCCESS,
  SEARCH_REPAIRS_SUCCESS,
  SAVE_CAR,
  SAVE_PRICE,
  ADD_CAR,
  REPLACE_CAR,
  LOAD_LOCATIONS_SUCCESS,
  ADD_LOCATION,
  REPLACE_LOCATION,
  LOAD_USERS_SUCCESS,
  LOAD_CUSTOMERS_SUCCESS,
  SET_TEMPLATE_ID,
  SET_SIDE_BAR,
  SET_SHOW_LIST,
  SET_SEARCH_QUERY_INVENTORY,
  SET_CAR_STATUS_OPTIONS,
  SAVE_TASK_FILTER,
  SET_FIRST_LOADING,
  SET_KEYWORD,
  SET_MENU_ID,
  SET_PAGE_NUMBER_INVENTORY,
  SET_CAR_FILTERS,
  SET_CAR_FILTERS_INITIAL,
  SET_ITEMS_BY_PAGE_QUANTITY,
  SET_MIN_VALUES,
  SET_MAX_VALUES,
  SET_CAR_FILTERS_AND_CURRENT_PAGE,
  SET_SALE_FILTERS,
  SET_SALE_FILTERS_INITIAL,
  SET_SALE_FILTERS_AND_CURRENT_PAGE,
  SET_PAGE_NUMBER_SALES,
  SET_USER_ROLES,
  SAVE_REPAIR_COST_FROM_INVENTORY,
  SET_TAX_EXEMPT,
  SET_DATA_DASHBOARD_LAYOUT,
  SET_NEW_FINANCE_APPLICATIONS_COUNTER,
  SET_UNREAD_MESSAGES_COUNTER,
  LOAD_MAKES_ALL_SUCCESS,
  SET_SIDE_BAR_BOTTOM,
  UPDATE_TESTIMONIALS,
  LOAD_ACTIVE_USERS_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_ACCOUNT_FAILURE,
  LOAD_ACTIVE_USERS_FAILURE,
  UPDATE_FEATURE_FLAGS_SUCCESS,
  UPDATE_FEATURE_FLAGS_FAILURE
} from '../actions'
import { isEmpty } from 'lodash'

const CAR_FILTERS_INITIAL = {from: 0, size: 20};
const ITEMS_BY_PAGE_QUANTITY_DEFAULT = 20;
const SALE_FILTERS_INITIAL = {}

let email = (state=null) => {
  return state;
};

let dealerId = (state= null) => {
  return state;
};

let firstName = (state= null, action) => {
  let {payload,type,error} = action;
  switch(type){
    case UPDATE_ACCOUNT_SUCCESS:
      return payload.firstName;
    case UPDATE_ACCOUNT_FAILURE:
      return {...error, error: true}
    default:
      return state;
  }
};

let urlImage = (state= null, action) => {
  let {payload, type, error} = action;
  switch(type){
    case UPDATE_ACCOUNT_SUCCESS:
      return payload.image;
    case UPDATE_ACCOUNT_FAILURE:
      return {...error, error: true}
    default:
      return state;
  }
};

const lastName = (state = null, action) => {
  let {payload,type,error} = action;
  switch(type){
    case UPDATE_ACCOUNT_SUCCESS:
      return payload.lastName;
    case UPDATE_ACCOUNT_FAILURE:
      return {...error, error: true}
    default:
      return state;
  }
};

const logoUrl = (state = null) => state;

// const carsInformation = (state=null,action) => {
//   let {payload,type} = action;
//   switch (type) {
//     case SEARCH_INVENTORY_PUBLIC_SUCCESS:
//       if (payload)
//         return payload;
//       break;
//     default:
//       return state;
//   }
// };

const cars = (state=null,action) => {
  let {payload,type} = action;
  switch (type) {
    case SEARCH_INVENTORY_SUCCESS:
      if (payload)
        return payload.cars;
      break;
    case REPLACE_CAR:
      if (payload)
        return payload;
      break;
    case ADD_CAR:
      if (payload)
        return state.concat(payload);
      break;
    default:
      return state;
  }
};

const optionsBody = (state=null,action) => {
  let {payload,type} = action;
  switch (type) {
    case SEARCH_INVENTORY_SUCCESS:
      if (payload)
        return payload.optionsBody;
      break;
    default:
      return state;
  }
};

const messages = (state=null,action) => {
  let {payload,type} = action;
  switch(type){
    case LOAD_MESSAGES_SUCCESS:
      if(payload)
        return payload;
      break;
    default:
      return state;
  }
};

const finances = (state=null,action) => {
  let{payload,type} = action;
  switch(type){
    case LOAD_FINANCES_SUCCESS:
      if(payload)
        return payload;
      break;
    default:
      return state;
  }
};

const makes = (state=null,action) => {
  let{payload,type} = action;
  switch(type){
    case LOAD_MAKES_SUCCESS:
      if(payload)
        return payload.make;
      break;
    default:
      return state;
  }
};

const models = (state=null,action) => {
  let{payload,type} = action;
  switch(type){
    case LOAD_MAKES_SUCCESS:
      if(payload)
        return payload.model;
      break;
    default:
      return state;
  }
};

const makesAll = (state = null,action) => {
  let{payload,type} = action;
  switch(type){
    case LOAD_MAKES_ALL_SUCCESS:
      if(payload)
        return payload.make;
      break;
    default:
      return state;
  }
};

const modelsAll = (state = null,action) => {
  let{payload,type} = action;
  switch(type){
    case LOAD_MAKES_ALL_SUCCESS:
      if(payload)
        return payload.model;
      break;
    default:
      return state;
  }
};

const repairs = (state=null,action) => {
  let{payload,type} = action;
  switch(type){
    case SEARCH_REPAIRS_SUCCESS:
      if(payload)
        return payload;
      break;
    default:
      return state;
  }
};

const saveCar = (state=null,action) => {
  let{type} = action;
  switch(type){
    case SAVE_CAR:
      return action.item;
    default:
      return state;
  }
};

const savePrice = (state=null,action) => {
  let{type} = action;
  switch(type){
    case SAVE_PRICE:
      return action.item;
    default:
      return state;
  }
};

const locations = (state=null,action) => {
  let {payload,type} = action;
  switch(type){
    case LOAD_LOCATIONS_SUCCESS:
    case REPLACE_LOCATION:
      if(payload)
        return payload;
      break;
    case ADD_LOCATION:
      if (payload)
        return state.concat(payload);
      break;
    default:
      return state;
  }
};

// const locationId = (state = null,action) => {
//   let{payload,type} = action;
//   switch(type){
//     case SELECTED_LOCATION_ID:
//       if(payload)
//         return payload;
//       break;
//     case PUBLIC_LOAD_LOCATIONS_SUCCESS:
//       if(isArray(payload) && payload.length === 1)
//         return payload[0].locationId;
//       else
//         return state;
//     default:
//       return state;
//   }
// };
//
// const locationsPublic = (state = null,action) => {
//   let {payload,type} = action;
//   switch(type){
//     case PUBLIC_LOAD_LOCATIONS_SUCCESS:
//       if(payload)
//         return payload;
//       break;
//     default:
//       return state;
//   }
// };

const users = (state=null,action) => {
  let {payload,type} = action;
  switch(type){
    case LOAD_USERS_SUCCESS:
      if(payload)
        return payload;
      break;
    default:
      return state;
  }
};

const customers = (state=null,action) => {
  let {payload,type} = action;
  switch(type){
    case LOAD_CUSTOMERS_SUCCESS:
      if(payload)
        return payload;
      break;
    default:
      return state;
  }
};

let templateName = (state=null) => {
  return state;
};

let templateContent = (state=null) => {
  return state;
};

let templateId = (state=null,action) => {
  let {payload,type} = action;
  switch(type){
    case SET_TEMPLATE_ID:
      return payload;
    default:
      return state;
  }
};

const showSideBar = (state=false,action) => {
  let {payload,type} = action;
  switch(type){
    case SET_SIDE_BAR:
      return payload;
    default:
      return state;
  }
};

const showSideBarOnBottom = (state= false, action) => {
  let {payload,type} = action;
  switch(type){
    case SET_SIDE_BAR_BOTTOM:
      return payload;
    default:
      return state;
  }
};

const showList = (state=false,action) => {
  let {payload,type} = action;
  switch(type){
    case SET_SHOW_LIST:
      return payload;
    default:
      return state;
  }
};

const searchQueryInventory = (state={},action) => {
  let {payload,type} = action;
  switch(type){
    case SET_SEARCH_QUERY_INVENTORY:
      return payload;
    default:
      return state;
  }
};

const attributeValuesToCarStatus = (state=null,action) => {
  let {payload,type} = action;
  switch(type){
    case SET_CAR_STATUS_OPTIONS:
      return payload;
    case SET_CAR_FILTERS_INITIAL:
      return payload.attributeValuesToCarStatus;
    default:
      return state;
  }
};

const menuId = (state=null,action) => {
  let {payload,type} = action;
  switch(type){
    case SET_MENU_ID:
      return payload;
    default:
      return state;
  }
};

const keyword = (state="",action) => {
  let {payload,type} = action;
  switch(type){
    case SET_KEYWORD:
      return payload;
    default:
      return state;
  }
};

const taskFilter = (state={type : "normal" ,priority: "all" ,userIds :[]},action) =>{
  let {type,payload} = action;
  switch (type) {
    case SAVE_TASK_FILTER:
      return payload;
    default:
      return state;
  }
};
const pageNumberInventory = (state= {currentPage : 1},action) => {
  let {payload,type} = action
  switch(type){
    case SET_PAGE_NUMBER_INVENTORY:
      return {...state, currentPage : payload}
    case SET_CAR_FILTERS_AND_CURRENT_PAGE:
     return {...state, currentPage : payload.currentPage}
    default:
      return state
  }
};

const isFirstLoading = (state=true,action) => {
  let {payload,type} = action;
  switch(type){
    case SET_FIRST_LOADING:
      return payload;
    default:
      return state;
  }
};

const carFilters = (state = CAR_FILTERS_INITIAL, action) => {
  let {payload,type} = action;
  switch(type){
    case SET_CAR_FILTERS:
      return {...state, ...payload};
    case SET_CAR_FILTERS_INITIAL:
    case SET_CAR_FILTERS_AND_CURRENT_PAGE:
      return {...state, ...payload.carFilters};
    case SET_MIN_VALUES:
      return {...state, minValues: payload.minValues};
    case SET_MAX_VALUES:
      return {...state, maxValues: payload.maxValues};
    default:
      return state;
  }
};

const itemsByPageQuantity = (state = ITEMS_BY_PAGE_QUANTITY_DEFAULT,action) => {
  let {payload,type} = action;
  switch(type){
    case SET_ITEMS_BY_PAGE_QUANTITY:
      return payload;
    default:
      return state;
  }
};

let dealerName = (state=null) => {
  return state;
};

let isSuperuser = (state=null) => {
  return state;
};

let isAccountOwner = (state=null) => {
  return state;
};

let userRoles = (state = {}, action) => {
  let {payload,type} = action;
  switch(type){
    case SET_USER_ROLES:
      return Object.assign({}, state, payload);
    default:
      return state;
  }
};


const saleFilters = (state = SALE_FILTERS_INITIAL, action) => {
  let {payload,type} = action;
  switch(type){
    case SET_SALE_FILTERS:
      return payload;
    case SET_SALE_FILTERS_INITIAL:
    case SET_SALE_FILTERS_AND_CURRENT_PAGE:
      return payload.saleFilters;
    default:
      return state;
  }
};

const pageNumberSale = (state= 1,action) => {
  let {payload,type} = action;
  switch(type){
    case SET_PAGE_NUMBER_SALES:
      return payload;
    case SET_SALE_FILTERS:
      return 1;
    case SET_SALE_FILTERS_AND_CURRENT_PAGE:
      return payload.currentPage;
    default:
      return state;
  }
};

const userId = (state=null) => {
  return state;
};

const dashboardLayout = (state = {}, action) => {
  let {payload,type} = action;
  switch(type){
    case SET_DATA_DASHBOARD_LAYOUT:
      return {...state,...payload}
    default:
      return state
  }
}

const newFinanceApplicationsCounter = (state= false, action) => {
  let {payload, type} = action;
  switch(type){
    case SET_NEW_FINANCE_APPLICATIONS_COUNTER:
      return payload;
    default:
      return state;
  }
};

const unreadMessagesCounter = (state= false, action) => {
  let {payload, type} = action;
  switch(type){
    case SET_UNREAD_MESSAGES_COUNTER:
      return payload;
    default:
      return state;
  }
};

const testimonials = (state = null, action) => {
  const {payload,type} = action;

  switch (type){
    case UPDATE_TESTIMONIALS:
      if(payload)
        return payload;
      break;
    default:
      return state;
  }
}

const activeUsers = (state= null, action) => {
  let {payload,type, error} = action;
  switch(type){
    case LOAD_ACTIVE_USERS_SUCCESS:
      return payload;
    case LOAD_ACTIVE_USERS_FAILURE:
      return {...error, error: true}
    case UPDATE_ACCOUNT_SUCCESS:
      if (isEmpty(state)){
        return state
      }
      return state.map(item => {
        if (item.id !== payload.id) {
          return item
        }
        const userToUpdate = {...payload}
        delete userToUpdate.roles
        return {
          ...item,
          ...userToUpdate
        }
      })
    default:
      return state;
  }
};

// From InitialState
const activeFlags = (state=null, action) => {
  let {payload, type, error} = action;
  switch (type) {
    case UPDATE_FEATURE_FLAGS_SUCCESS:
      return payload.activeFlags
    case UPDATE_FEATURE_FLAGS_FAILURE:
      return {...error, error: true}
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  userId,
  email,
  dealerId,
  firstName,
  lastName,
  urlImage,
  logoUrl,
  // carsInformation,
  messages,
  finances,
  makes,
  models,
  repairs,
  saveCar,
  savePrice,
  cars,
  optionsBody,
  locations,
  // locationId,
  // locationsPublic,
  users,
  customers,
  routing: routerReducer,
  templateName,
  templateContent,
  templateId,
  showSideBar,
  showList,
  searchQueryInventory,
  attributeValuesToCarStatus,
  menuId,
  keyword,
  pageNumberInventory,
  taskFilter,
  isFirstLoading,
  carFilters,
  itemsByPageQuantity,
  dealerName,
  isSuperuser,
  isAccountOwner,
  saleFilters,
  pageNumberSale,
  userRoles,
  dashboardLayout,
  newFinanceApplicationsCounter,
  unreadMessagesCounter,
  makesAll,
  modelsAll,
  showSideBarOnBottom,
  testimonials,
  activeUsers,
  activeFlags,
});

export default rootReducer;