import React, {Fragment, useState, useEffect, useContext, useRef} from 'react'
import DPSelectionDropDown from '../../../../../common/DPSelectionDropDown'
import {getDashboardId, getOptionsSelectDropdown, visualizationTableBuilder} from '../../../../util/Utils'
import {ReactSVG} from 'react-svg'
import {getVisualization} from '../../../../../Api'
import {DashboardContext} from '../../../Dashboard'
import moment from 'moment'
import {setDashboardLayout} from '../../../../../actions'
import {connect} from 'react-redux'
import DPKeyboardDatePicker from '../../../../../common/DPKeyboardDatePicker'
import {pathServer} from '../../../../../../common/Constants'
import {dateFormats} from '../../../../../Constants'

function DateFiltersForm({handleSubmit, dataLayout : {fromDate, toDate}}){
  const [from, setFrom] = useState(fromDate || moment())
  const [to, setTo] = useState(toDate || moment)

  return(
    <form onSubmit={handleSubmit} style={{order:3,marginLeft:"auto"}}>
      <div className="report-date-filter date-filter-left">
        <label className="date-label" htmlFor="report-date-from">From:</label>
        <DPKeyboardDatePicker
          id="report-date-from"
          name="report-date-from"
          value={from}
          onChange={setFrom}
          minDate={moment("12-25-1995", "MM-DD-YYYY")}
        />
      </div>
      <div className="report-date-filter date-filter-right" style={{float:"left", marginLeft:"20px"}}>
        <label className="date-label" htmlFor="report-date-to">To:</label>
        <DPKeyboardDatePicker
          id="report-date-to"
          name="report-date-to"
          value={to}
          onChange={setTo}
          minDate={moment("12-25-1995", "MM-DD-YYYY")}
        />
      </div>
      <button className="report-filter-search" type="submit">Search</button>
    </form>
  )
}

const Report = ({containerId="first", dashboardLayout, setDashboardLayout, ...props}) => {
  const dataLayout = dashboardLayout[containerId]
  const selectedReportId = dataLayout?.reportId || null
  const showDescriptionRef = useRef(false)
  const [visualization, setVisualization] = useState(null)
  const { reports, dashboards } = useContext(DashboardContext)
  const options = getOptionsSelectDropdown(reports, "id", "name")
  const selectedReport = reports.find(report => report.id === selectedReportId)
  const dashboardId = getDashboardId(dashboards)

  function getReportMetadata(selectedReportId){
    const selectedReport = reports.find(report => report.id === selectedReportId)
    return JSON.parse(selectedReport.metadata)
  }

  useEffect(() => {
    if(selectedReportId != null){
      getReportVisualization(selectedReportId)
    }
  }, [])

  function getReportVisualization(id){
    setDashboardLayout({[containerId]: {...dataLayout, reportId:id}})
    const currentDate = moment().format(dateFormats.MM_DD_YYYY_HYPHEN)
    const from = dataLayout?.fromDate ?? currentDate
    const to = dataLayout?.toDate ?? currentDate
    getVisualization(id, dashboardId, from && to ? {from,to} : null).then(
      ({data, responseCode}) => {
        if (responseCode === 200) {
          setVisualization(visualizationTableBuilder(data,getReportMetadata(id).columns))
        }
      }
    )
  }


  function closeReportDescription() {
    if (showDescriptionRef.current) {
      showDescriptionRef.current = false
      const reportDesc = document.getElementById(`reportDescription-${containerId}`)
      reportDesc.style.display = 'none'
      document.removeEventListener("click", closeReportDescription, false)
    }
  }

  function handleClick(e) {
    e.preventDefault()
    e.stopPropagation()
    if(!selectedReport){
      return
    }

    const reportDesc = document.getElementById(`reportDescription-${containerId}`)
    if(!showDescriptionRef.current){
      showDescriptionRef.current = true;
      reportDesc.style.display = 'block'
      document.addEventListener("click", closeReportDescription, false)
    }else{
      reportDesc.style.display = 'none'
      showDescriptionRef.current = false;
    }
  }

  function handleFilterReport(e){
    e.preventDefault()

    const formData = new FormData(e.target)
    const from = formData.get("report-date-from")
    const to = formData.get("report-date-to")
    setDashboardLayout({[containerId]: {...dataLayout, fromDate:from, toDate:to}})
    getVisualization(selectedReportId, dashboardId,{from, to}).then(
      ({data, responseCode}) => {
        if (responseCode === 200) {
          setVisualization(visualizationTableBuilder(data, getReportMetadata(selectedReportId).columns))
        }
      }
    )
  }

  return(
    <Fragment>
      <header>
        <DPSelectionDropDown
          style={{width: 190}}
          textStyle={{
            marginBottom: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          }}
          className='report-dropdown'
          //label={name}
          id={`dashboard-report-dropdown-${containerId}`}
          options={options}
          value={selectedReportId ? selectedReportId : null}
          onClick={ id => getReportVisualization(id)}
          errorMessage={null}
          disabled={false}
        />
        {
          !!selectedReport && <ReactSVG src={pathServer.PATH_IMG + "icon/ic_baseline_error.svg"}
                    beforeInjection={ svg => svg.classList.add("svg-icon-description")}
                    onClick={handleClick}
                    className="icon-error"/>
        }
        {
          !!selectedReport && (
            <div id={`reportDescription-${containerId}`} className="report-description">
              <header>{selectedReport?.name}</header>
              <section>{selectedReport?.description}</section>
            </div>
          )
        }
        {
          !!selectedReport && <DateFiltersForm handleSubmit={handleFilterReport} dataLayout={dataLayout} {...props} />
        }
      </header>
      {visualization}
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    dashboardLayout: state.dashboardLayout
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setDashboardLayout: (data) => dispatch(setDashboardLayout(data))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Report)